import { cx } from '@emotion/css';
import { Avatar, Rating } from '@sortlist-frontend/design-system';
import { FC } from 'react';

import { FakeUser } from '_data/users';

type ReviewCardProps = {
  className?: string;
  user: FakeUser;
};

export const ReviewCard: FC<ReviewCardProps> = (props) => {
  const { className, user } = { ...props };

  return (
    <div
      className={cx(
        className,
        'layout-row shadow-2 rounded-md bg-secondary-100 p-8 layout-row layout-align-start-center',
      )}>
      <Avatar src={user.avatarSrc} size="xs" />
      <div className="flex px-8 layout-column">
        <span className="small bold mb-4">{user.name}</span>
        {user.rating ? <Rating rate={user.rating} size={16} /> : null}
      </div>
      <div className="rounded-md flex-none bg-accent-100 text-accent-700 px-8 py-12 layout-row layout-align-center-center">
        <span className="bold">{user.rating}</span>
        <span>/5</span>
      </div>
    </div>
  );
};
