import { cx } from '@emotion/css';
import { FavoriteTwoTone, LabelTwoTone, MoreVertOutlined, VerifiedUserTwoTone } from '@mui/icons-material';
import { Avatar, COLORS, Rating } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';

import { FakeAgency, getFakeAgencies } from '_data/index';
import { providersConfig } from '_features/Providers/config';

import * as S from './styles';

type VisibilityIllustrationProps = {
  className?: string;
  agencyIndex?: number;
  bgColor: string;
};

export function VisibilityIllustration(props: VisibilityIllustrationProps) {
  const { className, agencyIndex, bgColor } = { ...props };
  const { t } = useTranslation(providersConfig.i18nNamespaces);
  const agency: FakeAgency =
    (agencyIndex && getFakeAgencies(t)[agencyIndex]) ||
    getFakeAgencies(t).find((agency) => agency.id === 'yourCompany') ||
    getFakeAgencies(t)[0];

  return (
    <S.IllustrationWrapper
      color={bgColor}
      className={cx(className, 'relative overflow-hidden layout-column layout-align-center-center')}>
      <S.AgencyCard className="relative shadow-2 bg-neutral-100 border border-danger-500 rounded-xl p-24 layout-column layout-align-center-center text-center">
        <div className="header layout-row layout-align-space-between-start width-100">
          <MoreVertOutlined className="text-secondary-500" />
          <Avatar src={agency.logoSrc} className="mb-24" shape="square" size="lg" />
          <FavoriteTwoTone style={{ color: COLORS.DANGER_500 }} />
        </div>
        <span className="h3 bold mb-8">{agency.name}</span>
        {agency.catchPhrase && <span className="small mb-8 text-secondary-900">{agency.catchPhrase}</span>}

        {agency.rating ? (
          <div className="mb-24 ratings layout-row layout-align-space-between-center">
            <span className="small text-secondary-500 mr-8">
              <b className="h6 bold text-primary-900">{agency.rating}</b>/5
            </span>
            <Rating size={15} rate={agency.rating}>
              <span className="ml-8 small text-secondary-500">
                (
                {agency.reviewsCount && agency.reviewsCount > 1
                  ? t('agency:results.card.reviews_other', { count: agency.reviewsCount })
                  : t('agency:results.card.reviews', { count: agency.reviewsCount })}
                )
              </span>
            </Rating>
          </div>
        ) : null}
        <div className="layout-row layout-wrap layout-align-center-center">
          <span
            className={cx(
              agency.promoted
                ? 'text-primary-500 bg-primary-200 border-primary-200'
                : 'text-secondary-700 border-secondary-300',
              'layout-row border layout-align-start-center m-4 text-truncate px-8 py-4 small rounded-sm',
            )}>
            <LabelTwoTone style={{ height: 16 }} />
            {t('agency:profile.about.promoted')}
          </span>
          <span
            className={cx(
              agency.verified
                ? 'text-success-500 bg-success-200 border-success-200'
                : 'text-secondary-700 border-secondary-300',
              'layout-row border layout-align-start-center m-4 text-truncate px-8 py-4 small rounded-sm',
            )}>
            <VerifiedUserTwoTone style={{ height: 14 }} /> {t('agency:profile.about.verified')}
          </span>
          {agency.skills.slice(0, 3).map((skill: string) => (
            <span
              className="m-4 text-truncate px-8 py-4 small text-secondary-700 bg-secondary-200 rounded-sm"
              key={skill}>
              {skill}
            </span>
          ))}
          {agency.skills.length > 3 && (
            <span className="m-4 text-truncate px-8 py-4 small text-secondary-700 bg-secondary-200 rounded-sm">
              +{agency.skills.length - 3}
            </span>
          )}
        </div>

        {agency.description && <p className="mt-16 text-secondary-500 small">{agency.description}</p>}
      </S.AgencyCard>
      <S.Grid className="p-16 layout-row layout-wrap">
        {Array.from(Array(4), (e, index: number) => (
          <S.AgencyCard key={index} className={cx({ 'hide-sm hide-xs': index >= 4 }, 'flex-50 p-8')}>
            <div className="border border-secondary-300 rounded-xl layout-column p-24 layout-align-start-stretch">
              <div className="layout-row layout-align-space-between-start">
                <div className="rounded p-8 bg-secondary-300" />
                <div className="p-40 rounded-sm bg-secondary-300 mb-16" />
                <div className="rounded p-8 bg-secondary-300" />
              </div>
              <section className="layout-column layout-align-center-center">
                <ul className="list-reset width-100 layout-align-center-center layout-column">
                  {Array.from(Array(2), (e, index: number) => (
                    <div
                      key={index}
                      className={cx(
                        index === 0 ? 'py-8 width-100' : 'py-4 width-60',
                        'rounded-sm mt-8 bg-secondary-300',
                      )}
                    />
                  ))}
                </ul>
                <Rating activeColorClass="text-secondary-300" rate={1} count={1} className="mt-16" size={16} />
                <ul className="list-reset width-100 mt-12 layout-align-center-center layout-row layout-wrap">
                  {Array.from(Array(5), (e, index: number) => (
                    <div key={index} className="m-4 rounded-sm px-24 py-12 bg-secondary-300" />
                  ))}
                </ul>
                <ul className="mt-12 list-reset width-100 layout-align-center-center layout-column">
                  {Array.from(Array(4), (e, index: number) => (
                    <div
                      key={index}
                      className={cx(index !== 1 ? 'width-75' : 'width-100', 'py-4 mt-8 rounded-md bg-secondary-300')}
                    />
                  ))}
                </ul>
              </section>
            </div>
          </S.AgencyCard>
        ))}
      </S.Grid>
    </S.IllustrationWrapper>
  );
}
