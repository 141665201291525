import { cx } from '@emotion/css';
import { StarRounded } from '@mui/icons-material';
import { TYPOGRAPHY } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';

import { providersConfig } from '_features/Providers/config';

import { FakeOpportunity, getFakeOpportunities } from '../../utils';
import * as S from './styles';

type Props = {
  className?: string;
};

export function OpportunityIllustration(props: Props) {
  const { className } = { ...props };
  const { t } = useTranslation(providersConfig.i18nNamespaces);
  const opportunities: FakeOpportunity[] = getFakeOpportunities(t);

  return (
    <div className={cx(className, 'layout-column')}>
      {opportunities.map((opportunity: FakeOpportunity, index: number) => (
        <S.Opportunity
          className={cx(
            { 'shadow-2': index === 1 },
            { 'shadow-3': index === 2 },
            { 'border border-secondary-700': index === 1 },
            index === 0 ? 'bg-secondary-200' : 'bg-neutral-100',
            'layout-column  layout-align-start-start relative rounded-lg p-32',
          )}
          key={opportunity.id}>
          {index === 1 && (
            <div className="layout-row layout-align-center-center py-4 px-8 rounded-xs small text-success-700 bg-success-200 mb-16">
              <StarRounded style={{ fontSize: TYPOGRAPHY.P }} className="mr-4" />
              <span>{t('providers:modules.opportunity.fake.newOpportunity')}</span>
            </div>
          )}
          <span className="h4 bold mb-8">{opportunity.title}</span>
          <div className=" mb-4 rounded-md overflow-hidden width-100 bg-secondary-300 my-4" />
          <div className=" mb-16 width-50 rounded-md overflow-hidden bg-secondary-300 my-4" />
          <span className="rounded-sm bg-success-300 text-success-700 py-8 px-16 layout-row layout-align-center-center">
            {t('providers:modules.opportunity.fake.btnLabel')}
          </span>
        </S.Opportunity>
      ))}
    </div>
  );
}
