import { cx } from '@emotion/css';
import { ReactNode } from 'react';

import * as S from './styles';

type ModuleTagProps = {
  className?: string;
  label: string;
  icon: ReactNode;
  palette?: 'primary' | 'warning' | 'secondary' | 'success' | 'danger' | 'neutral' | 'client' | 'agency';
  onClick?: () => void;
  style?: React.CSSProperties;
};

export function ClickableTag(props: ModuleTagProps) {
  const { className, label, icon, palette, style = {} } = { ...props };

  return (
    <S.Tag
      className={cx(className, palette ? `bg-${palette}-200` : null, 'mb-16 layout-row layout-align-start-start')}
      style={style}>
      <div
        className={cx(
          palette ? `text-${palette}-500` : null,
          'shadow-2 bg-secondary-100 rounded layout-column p-8 layout-align-center-center',
        )}>
        {icon}
      </div>
      <span className={cx(palette ? `text-${palette}-500` : null, 'ml-8 py-4 pr-16 flex text-truncate')}>{label}</span>
    </S.Tag>
  );
}
