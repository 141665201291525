import { cx } from '@emotion/css';
import { FC } from 'react';

import * as S from './styles';

type SectionShapeProps = {
  className?: string;
  height: number;
  color: string;
};

export const SectionShape: FC<SectionShapeProps> = (props) => {
  const { className, height, color } = { ...props };
  return <S.Shape color={color} height={height} className={cx(className, 'overflow-hidden relative')} />;
};
