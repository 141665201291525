import { Fragment } from 'react';

import { cx } from '@emotion/css';
import { FiberManualRecord } from '@mui/icons-material';
import { Avatar, TYPOGRAPHY } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';

import { FakeAgency } from '_data/agencies';
import { FakeUser, getFakeUsers } from '_data/index';
import { providersConfig } from '_features/Providers/config';

import * as S from './styles';

type Props = {
  className?: string;
  agency: FakeAgency;
};

export function FreeIllustration(props: Props) {
  const { className, agency } = { ...props };
  const reviewsCount = 12;
  const { t } = useTranslation(providersConfig.i18nNamespaces);
  const users = getFakeUsers(t);

  const getDotColor = (index: number) => {
    switch (index) {
      case 0:
        return '#F6789B';
      case 1:
        return '#F9AF1F';
      default:
        return '#86E2BA';
    }
  };

  return (
    <Fragment>
      <S.Shape
        className={cx(
          className,
          'width-100 bg-neutral-100 overflow-hidden border border-secondary-700 rounded-lg shadow-2-primary-900 layout-column',
        )}>
        <header className="bg-secondary-100 py-8 px-16">
          {Array.from(Array(3), (e, index: number) => (
            <FiberManualRecord key={index} style={{ color: getDotColor(index), fontSize: TYPOGRAPHY.SMALL }} />
          ))}
        </header>
        <main className="layout-row layout-wrap">
          <div className="flex-100 flex-gt-xs-33 layout-column layout-align-center-center p-16 text-center">
            <Avatar size="md" shape="square" src={agency.logoSrc} className="mb-16" />
            <p className="bold h6">{agency.name}</p>
            <section
              className="mt-32 layout-align-start-start layout-column width-100 hide-xs
            ">
              {Array.from(Array(4), (e, index: number) => (
                <S.Line
                  key={index}
                  className={cx(index ? `width-${(index + 4) * 10}` : 'width-100', 'rounded-sm bg-secondary-300 my-4')}
                />
              ))}
            </section>
          </div>
          <div className="flex layout-column p-8">
            <S.Cover id="cover" src={agency.coverSrc} className="hide-xs rounded-md width-100 bg-secondary-300 mb-16" />
            <div className="layout-column width-50 ">
              {Array.from(Array(4), (e, index: number) => (
                <S.Line
                  key={index}
                  className={cx(
                    index ? `width-${(index + 4) * 15}` : 'width-100',
                    'rounded-md overflow-hidden bg-secondary-300 my-4',
                  )}
                />
              ))}
            </div>
            <div className="layout-row mt-16">
              {agency.works?.slice(0, 3).map((work: FakeAgency['works'][0]) => (
                <S.Work key={work.title} className="flex-33 layout-column px-8">
                  <img
                    src={work.imageSrc}
                    alt={work.title}
                    className="flex width-100 overflow-hidden rounded-md bg-secondary-300"
                  />
                </S.Work>
              ))}
            </div>
          </div>
        </main>
      </S.Shape>
      <S.Reviews className="layout-row layout-wrap absolute">
        {users.slice(0, reviewsCount).map((user: FakeUser) => (
          <S.Review className="p-4 flex-50" key={user.id}>
            <S.ReviewCardWrapper className="review-card" user={user} />
          </S.Review>
        ))}
      </S.Reviews>
    </Fragment>
  );
}
