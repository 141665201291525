import { cx } from '@emotion/css';
import { Check } from '@mui/icons-material';
import { TYPOGRAPHY } from '@sortlist-frontend/design-system';

import { BenefitType } from '_features/Providers/data';

type Props = {
  className?: string;
  benefits: BenefitType[];
  isFree?: boolean;
};

export function BenefitsCards(props: Props) {
  const { className, benefits, isFree } = { ...props };

  return (
    <div className={cx(className, 'layout-row layout-wrap layout-align-start-stretch width-100')}>
      {benefits.map((benefit: BenefitType) => {
        const { id, icon, title, items } = benefit;
        return (
          <div key={id} className="p-8 flex-xs-100 flex-sm-50 flex-gt-sm-33 layout-column">
            <div
              key={'benefit-' + id}
              className={cx(
                isFree ? 'bg-neutral-100' : 'bg-secondary-100',
                'layout-column layout-align-start-start px-24 py-32 rounded-xl flex',
              )}>
              <div
                className={cx(
                  isFree ? 'text-primary-500 bg-primary-200' : 'bg-primary-900 text-neutral-100',
                  'icon p-16 layout-column layout-align-center-center rounded-lg mb-16',
                )}>
                {icon}
              </div>
              <p className="h5 text-left text-secondary-900 bold">{title}</p>

              {items && (
                <ul className="mt-32 layout-column layout-align-start-start list-reset flex">
                  {items.map((item, index) => (
                    <li
                      key={'benefit-' + id + '-item-' + index}
                      className={cx('py-4 layout-row layout-align-start-center')}>
                      <Check style={{ fontSize: TYPOGRAPHY.P }} className="text-success-500 mr-8 my-4" />
                      <span className="text-secondary-500 text-left">{item}</span>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
