import { cx } from '@emotion/css';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { FC } from 'react';

import { usePublicAppContext } from '_core/context/public-app-context';
import { QuoteData } from '_data/index';

import { Card } from './styles';

type QuoteCardProps = {
  quote: QuoteData;
  className?: string;
};

export const QuoteCard: FC<QuoteCardProps> = (props) => {
  const { quote, className } = { ...props };
  const { getUrl } = usePublicAppContext();
  const { t } = useTranslation(['common', 'agency']);
  const quoteCitation = quote.citation;
  const slicedCitation = quoteCitation.slice(0, 160);

  return (
    <Card
      className={cx(
        className,
        'layout-gt-xs-row layout-column border-content-900 shadow-2 overflow-hidden rounded-xl bg-neutral-100',
      )}>
      <div className="flex-gt-xs-65 flex-100 text-left layout-align-center-start layout-column flex-order-gt-xs-0 flex-order-1 p-32">
        <span className="bold h6">{quote.author}</span>
        <span className="mb-16 p text-secondary-500">{quote.authorRole}</span>

        <p className={cx(slicedCitation.length >= 95 ? 'h4' : 'h3', 'mb-32 bold')}>
          <span>{slicedCitation}</span>
          {quoteCitation.length > 160 && '...'}
        </p>
        {quote.slug && (
          <a href={getUrl('story', { storySlug: quote.slug })} className="underline text-primary-500">
            {t('common:quoteCard.linkLabel')}
          </a>
        )}
      </div>
      <div className="flex flex-order-gt-xs-1 flex-order-0">
        <img className="layout-fill" src={quote.logoSrc} alt={quote.imgAlt} />
      </div>
    </Card>
  );
};
